import axios from "axios";
import { ElMessage } from "element-plus";
import router from "../router";

// 环境的切换
if (process.env.NODE_ENV === "development") {
  axios.defaults.baseURL = "/api";
} else if (process.env.NODE_ENV === "debug") {
  axios.defaults.baseURL = "";
} else if (process.env.NODE_ENV === "production") {
  // axios.defaults.baseURL = "https://test.uummii.online/api";
  axios.defaults.baseURL = "https://php.uummii.online/api";
}
axios.defaults.timeout = 10000;
axios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded;charset=UTF-8;multipart/form-data";

// 请求拦截器
axios.interceptors.request.use(
  (config) => {
    /**
     * 每次发送请求之前判断是否存在 token，如果存在，则统一在http请求的header都加上token，不用每次请求都手动添加了
     * 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
     */
    const token = localStorage.getItem("token");
    token && (config.headers.Authorization = `Bearer ${token}`);
    return config;
  },
  (error) => {
    return Promise.error(error);
  }
);

// 响应拦截器
axios.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem("token");
      ElMessage.warning({
        message: "未登录或登录过期，请重新登录",
        type: "warning",
        duration: 1500,
        onClose: () => {
          router.replace({
            path: "/login",
            query: { redirect: router.currentRoute.fullPath },
          });
        },
      });
    } else if (error.response.status === 500) {
      ElMessage.error("页面加载失败，请刷新");
    }
    return Promise.reject(error);
  }
);

/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {object} params [请求时携带的参数]
 */
export function get(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params: params,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
}

/**
 * post方法，对应post请求
 * @param {string} url [请求的url地址]
 * @param {object} params [请求时携带的参数]
 */
export function post(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, params)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
}

import { get, post } from "../request";

// 跟读任务列表
export const getFollowList = (params) =>
  get("/followread/getFollowList", params);

// 课程信息列表
export const getClassInfoList = (params) =>
  get("/followread/getClassList", params);

// 添加跟读任务
export const addFollowTask = (params) =>
  post("/followread/addFollowTask", params);

// 编辑跟读任务
export const updateFollowTask = (params) =>
  post("/followread/updateFollowTask", params);

// 查看跟读任务
export const getFollowTaskInfo = (params) =>
  get("/followread/getFollowTaskInfo", params);

// 音频上传
export const uploadVoice = (params) => post("/common/uploadvoice", params);

// 图片上传
export const uploadPic = (params) => post("/common/uploadpic", params);

// 审核
export const auditFollowTask = (params) =>
  post("/followread/auditFollowTask", params);

// 上线
export const onlineFollowTask = (params) =>
  post("/followread/onlineFollowTask", params);

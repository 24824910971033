<template>
  <div>
    <el-page-header
      icon="el-icon-arrow-left"
      content="编辑跟读任务"
      @back="goBack"
    ></el-page-header>
    <div v-loading="loading">
      <el-form class="follow-form" label-width="140px">
        <el-form-item label="跟读中文：">
          <div>{{ followData.sentence_name }}</div>
        </el-form-item>
        <el-form-item label="跟读英文：">
          <div>{{ followData.sentence }}</div>
        </el-form-item>
        <el-form-item label="句子连读：">
          <div>{{ followData.sentence_content }}</div>
        </el-form-item>
        <el-form-item label="示范音频：">
          <div style="display: flex">
            <audio
              style="height: 40px"
              :src="followData.audio"
              controls="controls"
            ></audio>
          </div>
        </el-form-item>
        <el-form-item label="所属阶段：">
          <div>{{ followData.level_name }}</div>
        </el-form-item>
        <el-form-item label="所属主题：">
          <div>{{ followData.program_name }}</div>
        </el-form-item>
        <el-form-item label="是否备选：">
          <div>{{ followData.is_alternative === "1" ? "是" : "否" }}</div>
        </el-form-item>
      </el-form>
      <el-divider></el-divider>
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="140px"
        class="follow-form"
      >
        <el-form-item label="配图：" prop="image">
          <div class="follow-update">
            <div v-if="img" style="margin-right: 20px">
              <el-image
                style="width: 100px"
                :src="img"
                fit="contain"
              ></el-image>
            </div>
            <div class="update-group">
              <el-button type="primary" size="small" round>上传</el-button>
              <input
                name="audio"
                class="update-btn"
                type="file"
                accept="image/*"
                @change="handImage"
              />
              <el-input
                v-model="ruleForm.image"
                class="follow-audio"
              ></el-input>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="分享图：" prop="share_img">
          <div class="follow-update">
            <div v-if="src" style="margin-right: 20px">
              <el-image
                style="width: 100px"
                :src="src"
                fit="contain"
              ></el-image>
            </div>
            <div class="update-group">
              <el-button type="primary" size="small" round>上传</el-button>
              <input
                name="audio"
                class="update-btn"
                type="file"
                accept="image/*"
                @change="handUpFile"
              />
              <el-input
                v-model="ruleForm.share_img"
                class="follow-audio"
              ></el-input>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="上线时间：" prop="onlinetime">
          <el-date-picker
            v-model="ruleForm.onlinetime"
            type="date"
            placeholder="选择日期"
            @change="handDate"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')"
            >提 交</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import {
  getFollowTaskInfo,
  updateFollowTask,
  uploadPic,
} from "@/api/apiList/content-api";
export default {
  data() {
    return {
      followData: {},
      ruleForm: {
        id: "",
        image: "",
        share_img: "",
        onlinetime: "",
      },
      img: "",
      src: "",
      loading: true,
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    // 配图
    handImage(e) {
      let obj = {
        file: e.target.files[0],
        cateId: "3581",
      };
      let formData = new FormData();
      for (let key in obj) {
        formData.append(key, obj[key]);
      }
      uploadPic(formData).then((res) => {
        if (res.result === 200) {
          this.img = res.data;
          this.ruleForm.image = res.data;
          this.$message.success({
            message: res.msg,
            type: "success",
          });
        } else {
          this.$message.error("上传失败");
        }
      });
    },
    // 分享图
    handUpFile(e) {
      let obj = {
        file: e.target.files[0],
        cateId: "3581",
      };
      let formData = new FormData();
      for (let key in obj) {
        formData.append(key, obj[key]);
      }
      uploadPic(formData).then((res) => {
        if (res.result === 200) {
          this.src = res.data;
          this.ruleForm.share_img = res.data;
          this.$message.success({
            message: res.msg,
            type: "success",
          });
        } else {
          this.$message.error("上传失败");
        }
      });
    },
    // 上线日期格式转换
    handDate(e) {
      let m =
        new Date(e).getMonth() > 8
          ? new Date(e).getMonth() + 1
          : "0" + (new Date(e).getMonth() + 1);
      let d =
        new Date(e).getDate() > 9
          ? new Date(e).getDate()
          : "0" + new Date(e).getDate();
      this.ruleForm.onlinetime = new Date(e).getFullYear() + "-" + m + "-" + d;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let formData = new FormData();
          for (let key in this.ruleForm) {
            formData.append(key, this.ruleForm[key]);
          }
          updateFollowTask(formData).then((res) => {
            if (res.result === "200") {
              this.$message({
                type: "success",
                message: res.data.info,
                duration: 1500,
                onClose: () => {
                  this.$router.go(-1);
                },
              });
            }
          });
        }
      });
    },
  },
  mounted() {
    let obj = {
      id: this.$route.params.id,
    };
    getFollowTaskInfo(obj).then((res) => {
      if (res.result === "200") {
        if (res.data.onlinetime && res.data.onlinetime !== "0000-00-00") {
          let time = res.data.onlinetime.replaceAll("-", ",");
          this.ruleForm.onlinetime = new Date(time);
        } else {
          this.ruleForm.onlinetime = "";
        }
        this.followData = res.data;
        this.ruleForm.id = this.$route.params.id;
        this.ruleForm.image = "";
        this.ruleForm.share_img = "";
        this.img = res.data.image;
        this.src = res.data.share_img;
        this.loading = false;
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.follow-form {
  margin-top: 50px;

  .follow-update {
    display: flex;
    align-items: center;

    .update-group {
      position: relative;

      .update-btn {
        position: absolute;
        width: 56px;
        height: 32px;
        top: 4px;
        left: 0;
        z-index: 1;
        opacity: 0;
      }

      .follow-audio {
        position: absolute;
        width: 56px;
        height: 32px;
        top: 4px;
        left: 0;
        z-index: -1;
        opacity: 0;
      }
    }
  }
}
</style>
